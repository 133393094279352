<template>
  <transition name="slide-right" appear>
    <section v-if="cartOpen" :class="{ 'is-open': cartOpen }" class="cart">
      <drawers right @close="close">
        <template slot-scope="{ scrimId }">
          <drawer
            :scrim-id="scrimId"
            :color="color"
            open
            right
            class="flex flex-col cart__drawer"
            :autoclose="!giftNoteEditing"
            @update:open="close"
            @close-drawers="close"
          >
            <header class="font-bold uppercase cart__header">
              <h2>
                <template v-if="$apollo.queries.cart.loading">
                  Loading...
                </template>
                <template v-else-if="giftNoteEditing">
                  Digital gift note
                </template>
                <cart-button
                  v-else
                  icon="chevron-left"
                  close
                  class="cart__button"
                >
                  <span class="inline-block ml-3 align-middle">Cart</span>
                </cart-button>
              </h2>
            </header>
            <div v-if="giftNoteEditing" class="flex-auto pb-gutter">
              <gift-note-editor :cart-id="cartId" />
            </div>
            <div v-else class="flex-auto pb-gutter">
              <slide-group v-if="lines.length > 0" appear tag="ul">
                <line-item
                  v-for="({ node }, i) in displayedLineItems"
                  v-show="cartOpen"
                  :key="node.merchandise.id"
                  :cart-node="node"
                  :data-index="i"
                  :data-total="lines.length"
                  data-display="flex"
                  v-bind="node"
                  @update:quantity="debouncedUpdateLineItem(node, $event)"
                />
              </slide-group>
              <cart-clouds v-else :color.sync="color" />
            </div>

            <div
              v-if="filteredRebuyProducts.length > 0"
              class="flex-auto pb-gutter cart__rebuy-section"
            >
              <h2 class="font-bold">
                <span>you'll also love</span>
              </h2>
              <slide-group
                v-if="filteredRebuyProducts.length > 0"
                appear
                tag="ul"
              >
                <rebuy-line-item
                  v-for="product in filteredRebuyProducts"
                  v-show="cartOpen"
                  :key="product.id"
                  :data-total="filteredRebuyProducts.length"
                  data-display="flex"
                  :product="product"
                />
              </slide-group>
            </div>

            <div
              v-if="!giftNoteEditing"
              class="flex flex-col items-center justify-center flex-initial flex-grow-0 pt-header-top sm:pb-header-bottom"
            >
              <gift-note-status
                v-if="isShowingGiftNoteStatus"
                class="w-full mb-em"
              />
              <cta
                v-if="lines.length > 0"
                class="block w-full cart__cta text-md md:text-lg lg:text-xl 2xl:w-3/4"
                large
                light
                :disabled="!!loading"
                @click.prevent="goToCheckout"
              >
                Checkout
              </cta>
              <CanSpinner ref="loader"></CanSpinner>
            </div>
          </drawer>
        </template>
      </drawers>
    </section>
  </transition>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { ChainedErrorFactory } from 'typescript-chained-error'
import sortLineItems from '~/util/shopify/sort-line-items'
import { captureExceptionWithDetails } from '~/util/sentry'
import formatLineItemSchema from '~/util/shopify/format-line-item-schema'
import cartQuery from '~/shopify/queries/cart.gql'
import cartCreateMutation from '~/shopify/mutations/cartCreate.gql'
import cartAttributesUpdateMutation from '~/shopify/mutations/cartAttributesUpdate.gql'
import CartButton from '~/components/shop/cart-button.vue'
import CartClouds from '~/components/shop/cart-clouds.vue'
import LineItem from '~/components/shop/line-item.vue'
import RebuyLineItem from '~/components/shop/rebuy-line-item.vue'
import GiftNoteEditor from '~/components/shop/gift-note-editor.vue'
import GiftNoteStatus from '~/components/shop/gift-note-status.vue'
import CanSpinner from '~/components/loading.vue'

export default {
  name: 'Cart',

  components: {
    CartButton,
    CartClouds,
    GiftNoteStatus,
    GiftNoteEditor,
    LineItem,
    RebuyLineItem,
    CanSpinner
  },

  data: () => ({
    color: undefined,
    analyticsAdded: false,
    loading: false,
    nonCBDSite: process.env.nonCBDSite
  }),

  computed: {
    ...mapState({
      googleClientId: state => state.googleClientId,
      googleSessionId: state => state.googleSessionId,
      segmentAid: state => state.segmentAid,
      cartId: state => state.storage.cartId,
      overlay: state => state.screen.overlay,
      giftNoteEditing: state => state.shop.giftNote.editorIsOpen,
      featureFlag: state => state.featureFlag,
      cbdWarning: state => state.settings.cart.cbdWarning,
      powderProductNote: state => state.settings.cart.shippingNotePowderProducts
    }),
    ...mapGetters('shop', {
      productByShopifyId: 'productByShopifyId',
      promoProductByShopifySKU: 'promoProductByShopifySKU',
      cartHasGiftCard: 'cartHasGiftCard',
      isShowingGiftNoteStatus: 'cartCanBeGiftOrder',
      allPromotionSKUs: 'allPromotionSKUs',
      allPromotionSizes: 'allPromotionSizes',
      findPromotionalSKUs: 'findPromotionalSKUs',
      findLimitedPromotionalSKUs: 'findLimitedPromotionalSKUs',
      rebuyProducts: 'rebuyProducts',
      productById: 'productById'
    }),
    filteredRebuyProducts() {
      return this.rebuyProducts.filter(rebuyProduct => {
        const product = this.productByShopifyId(rebuyProduct.id)

        if (this.nonCBDSite && product?.cbd) {
          return false
        }

        return true
      })
    },
    cartOpen() {
      return this.overlay === 'cart'
    },
    displayedLineItems() {
      return this.lines.filter(item => {
        return !this.allPromotionSKUs.includes(item.node.merchandise.sku)
      })
    },
    lines() {
      const cart = this.cart
      let items = []
      if (cart && cart.lines) {
        items = sortLineItems(cart.lines.edges)
        items = this.setLineItemNotes(items)
        items = this.setTitles(items)
      }
      return items
    }
  },

  apollo: {
    $client: 'cart',
    cart: {
      query: cartQuery,
      debounce: process.env.shopifyRequestDebounce,
      variables() {
        return {
          id: this.cartId
        }
      },
      update(data) {
        const cart = data.cart

        if (!cart) {
          this.createCart()
        } else {
          this.setCart(cart)
          this.syncGiftNote()
          return cart
        }
      },
      skip() {
        return !this.cartId
      }
    }
  },

  watch: {
    googleSessionId() {
      this.updateCartAttributes()
    },
    cartId() {
      this.updateCartAttributes()
    },
    cart() {
      this.$nextTick(() => this.$bus.$emit('cartUpdate', this.cartId))
    },
    lines(newItems, oldItems) {
      // watch for changes in cart and refetch rebuy recommendations if it changes
      const newLength = newItems.length
      const oldLength = oldItems.length
      if (newLength > 0 && newLength !== oldLength) {
        this.getRebuyProducts()
      }
    },
    cartOpen(open) {
      if (open) {
        this.$segment.track('Cart Viewed', {
          google_client_id: this.googleId,
          cart_id: this.cartId,
          products: this.lines.map(({ node }) => ({
            product_id: node.merchandise.product.id,
            sku: node.merchandise.sku,
            name: node.title,
            variant: node.merchandise.title,
            price: node.merchandise.price.amount,
            url: `${process.env.canonicalUrl}${this.documentPath(
              this.productByShopifyId(node.merchandise.product.id)
            )}`
          }))
        })
      }
    }
  },

  created() {
    this.debouncedSetup = debounce(
      this.setup,
      process.env.shopifyRequestDebounce
    )
    this.debouncedUpdateCartAttributes = debounce(
      this.updateCartAttributes,
      process.env.shopifyRequestDebounce
    )
    this.debouncedUpdateLineItem = debounce(
      this.updateLineItem,
      process.env.shopifyRequestDebounce
    )
  },

  mounted() {
    this.$nextTick(this.debouncedSetup)
    this.$bus.$on('createCart', this.createCart)
    this.$bus.$on('updateCartAttributes', this.debouncedUpdateCartAttributes)

    // TD - This ensures that the submit button will be
    // re-enabled if the user goes to the Shopify cart
    // and uses the "back" button to come back.
    window.addEventListener('pagehide', event => {
      this.loading = false
      this.close()
    })
  },

  beforeDestroy() {
    this.$bus.$off('createCart', this.createCart)
    this.$bus.$off('updateCartAttributes', this.updateCartAttributes)
  },

  methods: {
    ...mapActions('shop', {
      setCartId: 'setCartId',
      addToCart: 'addToCart',
      fetchRebuyRecommendations: 'fetchRebuyRecommendations',
      clearRebuyRecommendations: 'clearRebuyRecommendations',
      gotoCheckoutUrl: 'gotoCheckoutUrl',
      giftNoteEditorClose: 'giftNoteEditorClose'
    }),
    ...mapMutations({
      setOverlay: 'screen/setOverlay',
      setCart: 'shop/setCart',
      syncGiftNote: 'shop/syncGiftNote'
    }),
    async setup() {
      const currentDeploy = process.env.deployId
      const previousDeploy = this.$storage.getUniversal('deployId')
      if (currentDeploy !== previousDeploy) {
        // clear cart on new deployments
        this.$storage.setUniversal('deployId', currentDeploy)
        this.createCart()
        return
      }
      await this.setCartId()
    },
    buildCartAttributes(existingAttributes) {
      const googleClientId = this.googleClientId
      const googleSessionId = this.googleSessionId
      const segmentAid = this.segmentAid
      let attributes = {}

      if (!googleClientId || !googleSessionId || !segmentAid) {
        return
      }

      if (!existingAttributes.find(e => e.key === 'google-clientID')) {
        attributes['google-clientID'] = googleClientId
      }

      if (!existingAttributes.find(e => e.key === 'google-sessionID')) {
        attributes['google-sessionID'] = googleSessionId
      }

      if (!existingAttributes.find(e => e.key === 'segment-clientID')) {
        attributes['segment-clientID'] = segmentAid
      }

      const localStorageKeys = ['utm', 'aspire']

      localStorageKeys.forEach(key => {
        const entry = localStorage.getItem(key)

        if (entry) {
          const value = JSON.parse(entry)?.value
          attributes = {
            ...attributes,
            ...value
          }
        }
      })

      return Object.entries(attributes).map(attribute => {
        return {
          key: attribute[0],
          value: attribute[1] || ''
        }
      })
    },
    async createCart() {
      const variables = {
        input: {}
      }
      try {
        const attributes = this.buildCartAttributes(this.cart?.attributes || [])

        if (attributes) {
          variables.input.attributes = attributes
        }

        const cart = await this.$apollo
          .mutate({ mutation: cartCreateMutation, variables })
          .then(({ data: { cartCreate } }) => cartCreate.cart)
        this.setCartId(cart.id)
        this.setCart(cart)
        this.syncGiftNote()
        return cart
      } catch (e) {
        const errorCustom = ChainedErrorFactory.make(
          new Error('Cart Creation Failed'),
          e
        )
        captureExceptionWithDetails(this.$sentry, errorCustom, variables)
      }
      this.$bus.$emit('cartUpdate', this.cartId)
    },
    async updateCartAttributes() {
      if (this.analyticsAdded || !this.cartId || !this.googleSessionId) {
        return
      }
      this.analyticsAdded = true

      const attributes = this.buildCartAttributes(this.cart?.attributes || [])

      if (!attributes) {
        return
      }

      // we need to preserve existing attributes
      const existingAttributes =
        this.cart?.attributes.map(({ key, value }) => ({
          key,
          value
        })) || []

      const variables = {
        cartId: this.cartId,
        attributes: [...existingAttributes, ...attributes]
      }

      try {
        await this.$apollo.mutate({
          mutation: cartAttributesUpdateMutation,
          variables
        })
        this.syncGiftNote()
      } catch (e) {
        const errorCustom = ChainedErrorFactory.make(
          new Error('Failed to update cart attributes'),
          e
        )
        captureExceptionWithDetails(
          this.$sentry,
          errorCustom,
          JSON.stringify(variables)
        )
      }
    },
    getRebuyProducts() {
      if (this.lines && this.lines.length) {
        this.fetchRebuyRecommendations()
      } else {
        this.clearRebuyRecommendations()
      }
    },
    updateLineItem(line, quantity) {
      const edge = formatLineItemSchema(line)
      edge.node.quantity = quantity
      this.addToCart({ edge })

      if (!this.lines.length) {
        this.clearRebuyRecommendations()
      }
    },
    setTitles(lines) {
      return lines.map(item => {
        return {
          ...item,
          node: {
            ...item.node,
            title: item.node.merchandise.product.title
          }
        }
      })
    },
    setLineItemNotes(lines) {
      const cartIncludesDrinks = lines.some(item => {
        return item.node.merchandise.product.productType === 'Sparkling Water'
      })

      return lines.map(item => {
        const product = this.productByShopifyId(
          item.node.merchandise.product.id
        )

        if (!product) {
          return item
        }

        if (product.ingredients) {
          const ingredients = product.ingredients.map(ingredient => {
            return ingredient.title
          })

          if (
            !process.env.samplerSplash &&
            (ingredients.includes('broad spectrum hemp') ||
              ingredients.includes('broad spectrum extract'))
          ) {
            item.node.note = this.cbdWarning
          }
        }

        if (
          item.node.merchandise.product.productType === 'Powders' &&
          cartIncludesDrinks
        ) {
          item.node.note = this.powderProductNote
        }

        return item
      })
    },
    async goToCheckout() {
      this.loading = true
      this.$refs.loader.start()

      await this.handlePromotionalItems()

      this.gotoCheckoutUrl()
    },
    async handlePromotionalItems() {
      const lines = await this.clearPromotionItems(this.lines)
      const packSizesWithQuantities = lines
        .map(function(line) {
          const options = line.node.merchandise.selectedOptions
          const packSize = options.find(function(e) {
            return e.name === 'Pack Size'
          })
          return {
            quantity: line.node.quantity,
            size: packSize?.value
          }
        })
        .filter(function(e) {
          return e.size != null
        })
      const packSizes = packSizesWithQuantities.map(function(e) {
        return e.size
      })
      // allPromotionSizes is sorted from biggest
      // pack size to smallest.
      for (const size of this.allPromotionSizes) {
        if (packSizes.includes(size)) {
          const sizeWithQuantity = packSizesWithQuantities.find(function(e) {
            return e.size === size
          })
          // If there is only one pack size, we add the limited
          // promotional items.
          if (packSizes.length === 1 && sizeWithQuantity.quantity === 1) {
            await this.addLimitedPromotionalItem(size)
          }

          await this.addPromotionalItem(size)
          break
        }
      }
    },
    async addLimitedPromotionalItem(packSize) {
      const limitedSkus = this.findLimitedPromotionalSKUs(packSize)

      for (const sku of limitedSkus) {
        await this.createPromotionalProductLineItem(sku)
      }
    },
    async addPromotionalItem(packSize) {
      const skus = this.findPromotionalSKUs(packSize)

      for (const sku of skus) {
        await this.createPromotionalProductLineItem(sku)
      }
    },
    async createPromotionalProductLineItem(sku) {
      const product = this.promoProductByShopifySKU(sku)
      if (!product) {
        return
      }

      const variant = product.variants.edges.find(v => {
        return v.node.sku === sku
      })
      const line = formatLineItemSchema(
        {
          id: product.id,
          productType: 'Merch',
          title: product.title,
          hiddenPromotion: true,
          quantity: 1,
          attributes: [],
          variant: variant.node
        },
        'product'
      )
      await this.addToCart({ edge: line, additive: true })
    },
    async clearPromotionItems(items) {
      const promotionItems = items.filter(item => {
        return this.allPromotionSKUs.includes(item.node.merchandise.sku)
      })

      if (promotionItems.length === 0) {
        return items
      }

      for (const promotionLineItem of promotionItems) {
        const edge = formatLineItemSchema(promotionLineItem.node)
        edge.node.quantity = 0
        await this.addToCart({ edge })
      }

      return items.filter(item => {
        return !this.allPromotionSKUs.includes(item.node.merchandise.sku)
      })
    },
    close() {
      this.giftNoteEditorClose()
      if (this.cartOpen) {
        this.setOverlay()
      }
    }
  }
}
</script>

<style>
.cart {
  color: theme('colors.midnight-blue');
  content-visibility: hidden;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  position: fixed;
  top: 0;
  width: var(--win-width);
  z-index: theme('zIndex.drawer');
}

.cart.is-open {
  content-visibility: visible;
}

.cart__drawer {
  width: 100%;
}

.cart .drawer__container,
.cart .drawer__content {
  min-height: 100%;
  width: 100%;
}

.cart .drawer__container {
  padding-top: 0;
}

.cart .drawer__content {
  display: flex;
  flex-direction: column;
}

.cart__header {
  align-items: flex-end;
  box-sizing: content-box;
  display: flex;
  flex: 0 1 auto;
  height: var(--header-height-inner);
  justify-content: flex-end;
  padding-bottom: var(--header-gutter-bottom);
  padding-top: var(--header-gutter-top);
  position: relative;
  text-align: right;
  top: 2px;
}

.cart__header h2 {
  position: relative;
  top: 0.1em;
}

.cart__button .close__icon {
  display: none;
}

.cart__cta.cta {
  --cta-background: theme('colors.white');
  --cta-border-color: theme('colors.midnight-blue');
  --cta-text-color: theme('colors.midnight-blue');
  --shadow-background: theme('colors.blue');
}

.cart__rebuy-section {
  border-top: 2px solid theme('colors.ultramarine');
}

.cart__rebuy-section h2 {
  margin: 20px 0;
}

@screen md {
  .cart {
    z-index: theme('zIndex.cart');
  }

  .cart__drawer {
    max-width: theme('spacing.200');
    width: theme('spacing.win-w-1/2');
  }

  .cart__header {
    justify-content: flex-start;
    text-align: left;
  }

  .cart__button .close__icon {
    display: inline-block;
  }
}

@screen 2xl {
  .cart__drawer {
    width: theme('spacing.win-w-1/2');
  }
}
</style>
