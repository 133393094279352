<template>
  <client-only>
    <ValidationObserver
      ref="form"
      v-slot="{ errors }"
      tag="form"
      class="relative max-w-xl leading-tight text-left sign-up"
      novalidate
      @submit.prevent="submit"
    >
      <div class="flex flex-no-wrap items-end">
        <ValidationProvider
          name="email"
          tag="div"
          rules="required|email"
          class="flex-auto lowercase whitespace-no-wrap"
          mode="lazy"
        >
          <div class="relative">
            <fade>
              <label
                v-if="!focused && !email"
                :for="`email-${$_uid}-input`"
                class="absolute left-0 font-medium transform -translate-y-1/2 text-waterloo"
                >Email</label
              >
            </fade>
            <input
              :id="`email-${$_uid}-input`"
              v-model="email"
              type="email"
              class="block w-full"
              @focus="focus"
              @blur="blur"
            />
          </div>
        </ValidationProvider>
        <button
          type="submit"
          class="relative flex-initial font-medium text-center ml-1/2em mb-1/2em top-px link link--no-focus link--always-active"
          @click.prevent="submit"
        >
          <flipper :value="buttonText" />
        </button>
      </div>
      <fade appear>
        <ul
          v-if="errors && Object.values(errors).flat().length"
          class="absolute left-0 w-full"
        >
          <li
            v-for="(error, i) in Object.values(errors).flat()"
            :key="`error-${i}`"
            class="text-xs opacity-75 text-waterloo mb-1/2em"
          >
            {{ error }}
          </li>
        </ul>
      </fade>
    </ValidationObserver>
  </client-only>
</template>

<script>
import wait from 'waait'
import { mapState } from 'vuex'
import signupMutation from '~/graphql/mutations/signup.gql'

export default {
  name: 'SignUp',

  props: {
    listId: {
      default: null,
      type: String
    }
  },

  data: () => ({
    email: '',
    state: 'initial',
    focused: false
  }),

  computed: {
    ...mapState({
      defaultListId: state => state.settings.klaviyo.listId
    }),
    buttonText() {
      switch (this.state) {
        case 'sending':
          return 'sending'
        case 'sent':
          return 'ttyl!'
        case 'error':
          return 'oops'
        default:
          return 'sign up'
      }
    }
  },

  watch: {
    email(address) {
      if (!address) {
        requestAnimationFrame(() => {
          this.$refs.form.reset()
        })
      }
      this.state = 'initial'
    }
  },

  methods: {
    focus() {
      this.focused = true
    },
    blur() {
      this.focused = false
    },
    async submit() {
      const email = this.email
      const listId = this.listId || this.defaultListId || null
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }
      this.state = 'sending'
      try {
        this.$segment.identify({ email })
        var klaviyo = window.klaviyo || [] // eslint-disable-line
        klaviyo.push(['identify', { $email: email }])
        // show 'sending' text for at least a beat
        await wait(1000)
        try {
          await this.$apollo.mutate({
            mutation: signupMutation,
            variables: { email, listId }
          })
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
          this.$sentry.captureException(e)
        }
        this.state = 'sent'
        await wait(2000)
        this.email = ''
        this.$emit('success')
      } catch (e) {
        const error = e.message
        this.$sentry.captureException(e)
        this.state = 'error'
        this.$refs.form.setErrors({ email: [error] })
        await wait(5000)
        if (this.state !== 'initial') {
          this.state = 'initial'
        }
      }
    }
  }
}
</script>

<style scoped>
label,
input,
ul {
  @apply py-1/2em;
}

label {
  pointer-events: none;
}

input {
  border-bottom: 1px solid currentColor;
}

input:focus {
  outline: 0;
}

button {
  height: 1.1em;
  line-height: 1.1;
  width: 6em;
}
</style>
