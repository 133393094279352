import groq from 'groq'
import pageFields from '../fields/page'
import productFields from '../fields/product'

export default alt => groq`
  *[_type == "product"]{
    ...${pageFields},
    ...${productFields(alt)}
  }
`
