import groq from 'groq'
import imageField from './image'

export default groq`
  defined(thumbAlt) => {
    "thumb": thumbAlt{
      ...,
      image{
        ...${imageField}
      }
    }
  },
  !defined(thumbAlt) && defined(thumb) => {
    "thumb": thumb{
      ...,
      image{
        ...${imageField}
      }
    }
  }
`
