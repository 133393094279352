<template>
  <li class="flex flex-no-wrap line-item">
    <div
      v-if="merchandise.image"
      class="flex-grow-0 flex-shrink-0 w-20 pr-em xs:pr-6 xs:w-32 lg:w-24 2xl:w-32"
    >
      <float
        v-if="productPath"
        :to="productPath"
        :tag="productPath ? 'recess-link' : 'div'"
        class="relative block w-full h-24 link link--never-fade"
      >
        <recess-image
          :src="merchandise.image.transformedSrc"
          :max-width="200"
          :alt="title"
          contain
          fluid
        />
      </float>
    </div>
    <div class="flex-auto pr-4 sm:pr-6">
      <recess-link
        v-if="productPath"
        :to="productPath"
        class="link link--never-fade"
      >
        <h3 class="text-sm leading-tight xs:text-base sm:text-lg mb-1/2em">
          {{ title }}
        </h3>
      </recess-link>
      <div class="text-sm">
        <h5 class="mb-1/2em">${{ price }}</h5>
        <h4 class="lowercase mb-1/2em">
          {{ merchandise.title === defaultTitle ? '' : merchandise.title }}
          <template v-if="sellingPlanLabel">
            {{ sellingPlanLabel }}
          </template>
        </h4>
        <div class="flex flex-no-wrap items-center mb-2em">
          <number-input
            :value="quantity"
            :min="1"
            :max="maxQuantity"
            label="Qty"
            class="flex-auto text-sm font-medium"
            @change="updateQuantity"
          />
        </div>
        <h5 v-if="note" class="leading-5 opacity-50">
          <sanity-blocks :blocks="note" />
        </h5>
      </div>
    </div>
    <div class="flex-grow-0">
      <button class="text-xl link" @click.prevent="remove">
        <fa :icon="['fal', 'times']" />
      </button>
    </div>
  </li>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import roundNumber from '~/util/number/round'
import NumberInput from '~/components/form/number-input.vue'

export default {
  name: 'LineItem',

  components: {
    NumberInput
  },

  props: {
    id: {
      default: null,
      type: [String, Number]
    },
    title: {
      default: null,
      type: String
    },
    merchandise: {
      default: () => {},
      type: Object
    },
    quantity: {
      default: 1,
      type: Number
    },
    attributes: {
      default: () => [],
      type: Array
    },
    cartNode: {
      default: () => {},
      type: Object
    },
    note: {
      default: null,
      type: Array
    },
    sellingPlanAllocation: {
      default: null,
      type: Object
    }
  },

  data() {
    return {
      samplerSplash: process.env.samplerSplash,
      defaultTitle: 'Default Title'
    }
  },

  computed: {
    ...mapState({
      googleId: state => state.googleId,
      cartId: state => state.storage.cartId
    }),
    ...mapGetters('shop', {
      productByShopifyId: 'productByShopifyId'
    }),
    product() {
      const merchandise = this.merchandise
      return merchandise
        ? this.productByShopifyId(merchandise.product.id)
        : null
    },
    productPath() {
      return this.documentPath(this.product)
    },
    thumb() {
      const product = this.product
      let thumb
      if (product && product.thumb) {
        thumb = product.thumb.image
      }
      return thumb
    },
    sellingPlanLabel() {
      return this.sellingPlanAllocation?.sellingPlan?.name
    },
    maxQuantity() {
      const merchandise = this.merchandise
      if (!merchandise) {
        return undefined
      }
      const available = merchandise.availableForSale
      let quantityAvailable = merchandise.quantityAvailable
      if (available && quantityAvailable <= 0) {
        quantityAvailable = 9999
      } else if (!available) {
        quantityAvailable = this.quantity
      }
      return quantityAvailable
    },
    price() {
      return roundNumber(this.merchandise.price.amount)
    }
  },

  methods: {
    updateQuantity(quantity) {
      this.$emit('update:quantity', quantity)
    },
    remove() {
      const cartId = this.cartId
      const merchandise = this.merchandise
      const product = this.product || merchandise.product
      const productPath = this.productPath
      this.updateQuantity(0)
      this.$segment.track('Product Removed', {
        google_client_id: this.googleId,
        cart_id: cartId,
        product_id: product.id,
        sku: merchandise.sku,
        name: product.title,
        variant: merchandise.title,
        price: merchandise.price.amount,
        url: `${process.env.canonicalUrl}${productPath}`
      })
    }
  }
}
</script>

<style scoped>
.line-item {
  border-top: 2px solid theme('colors.ultramarine');
  padding: var(--header-gutter-top) 0;
}

.line-item /deep/ .number {
  --number-border: 0;
  --number-border-radius: 0;
  --number-height: 1em;
  --number-background: transparent;
  --number-gutter: 0 0.75em;
  align-items: center;
  display: flex;
  flex: nowrap;
}

.line-item /deep/ .number__label {
  margin-right: 0.75em;
  text-transform: uppercase;
}
</style>
