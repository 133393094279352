import imageField from './image'

export default {
  title: 'Thumbnail',
  name: 'thumb',
  type: 'object',
  options: {
    collapsible: true
  },
  fields: [
    {
      ...imageField,
      description:
        'Representative, close-cropped image with transparent background',
      validation: Rule => Rule.required()
    },
    {
      title: 'Hover Images',
      name: 'hovers',
      type: 'array',
      description:
        'Optional images that appear when the thumbnail is hovered over',
      of: [imageField],
      validation: Rule =>
        Rule.max(6).error("A thumbnail can't have more than 6 hover images")
    }
  ]
}
