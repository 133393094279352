import groq from 'groq'
import productFields from '../fields/product'
import pageFields from '../fields/page'
import { categoryFields } from './product-menu'

// TODO clean this up
export default groq`
  *[_type == "shopMenu"][0]{
    ...,
    shopMenuSections[] {
      ...,
      shopMenuSectionItems[] {
        ...,
        cbd,
        shopMenuItemSubmenu {
        	...,
        	shopSubmenuProductCategories[]-> {
            ...${categoryFields},
            _type,
            cbd,
            "slug": slug.current,
            products[]-> {
              ...${pageFields},
              ...${productFields()},
              colors,
            },
            title,
            backgroundColors,
            backgroundImage {
              asset-> {
                _id
              }
            }
          }
      	}
      }
    }
  }
`
