// import React from 'react'
import { MdShoppingCart } from 'react-icons/md'
import basicEditor from '../blocks/basic-editor'
import contentEditor from '../blocks/content-editor'
import faviconField from '../fields/favicon'
import linkField from '../fields/link'
import thumbField from '../fields/thumb'
import profileField from '../fields/profile'
import featuresField from '../fields/features'
import socialMedia from '../fields/social-media'
import mediaField from '../fields/media'
import galleryField from '../fields/gallery'
import colorsField from '../fields/colors'
// import ShopifyProductSearch from '../../components/shopify/product-search'
import { ShopifyProductInput } from '../../components'

export default {
  name: 'product',
  title: 'Product',
  type: 'document',
  icon: MdShoppingCart,
  fieldsets: [
    {
      name: 'hero',
      title: 'Hero',
      options: {
        collapsible: true
      }
    },
    {
      name: 'description',
      title: 'Product Description',
      options: {
        collapsible: true
      }
    },
    {
      name: 'attributes',
      title: 'Attributes',
      options: {
        collapsible: true
      }
    },
    {
      name: 'water',
      title: 'Sparkling Water Content',
      options: {
        collapsible: true
      }
    },
    {
      name: 'realitywear',
      title: 'Realitywear Content',
      options: {
        collapsible: true
      }
    },
    {
      name: 'related',
      title: 'Related Content',
      options: {
        collapsible: true
      }
    },
    {
      name: 'alt',
      title: 'Scrubbed Content',
      options: {
        collapsible: true
      }
    }
  ],
  fields: [
    {
      title: 'CBD',
      name: 'cbd',
      type: 'boolean',
      description:
        'If CBD is enabled, this record will not be shown on the non-CBD storefront.'
    },
    {
      title: 'Title',
      name: 'title',
      type: 'string',
      validation: Rule => Rule.required().error('A title is required.')
    },
    {
      title: 'Slug',
      name: 'slug',
      type: 'slug',
      description: 'The URL path this page will exist at',
      options: {
        source: 'title',
        maxLength: 96
      },
      validation: Rule => Rule.required().error('A slug is required.')
    },
    {
      title: 'Hide from Navigation',
      name: 'hideFromNavigation',
      type: 'boolean',
      layout: 'checkbox',
      description: 'Whether to show the product in the navigation'
    },
    {
      title: 'Hide from Collection',
      name: 'hideFromCollection',
      type: 'boolean',
      layout: 'checkbox',
      description: 'Whether to show the product in its collection page'
    },
    {
      title: 'Category',
      name: 'category',
      type: 'reference',
      to: [{ type: 'productCategory' }],
      options: {
        filter: '!(defined(parentCategories))'
      },
      validation: Rule => Rule.required().error('A category is required.')
    },
    {
      title: 'Waitlist Klaviyo List ID',
      description:
        'If product unavailable in shopify (stock is zero and the "continue selling when out of stock" option is off) and this field is set, then a waitlist sign up form will appear. This is the six digit part of the url when viewing the klaviyo list. e.g. Wb9YV7 in https://www.klaviyo.com/list/Wb9YV7/magnesium-powder-waitlist',
      name: 'waitlistKlaviyoListId',
      type: 'string',
      validation: Rule => Rule.min(6).max(6)
    },
    {
      title: 'Waitlist Header',
      name: 'waitlistHeader',
      type: 'string'
    },
    {
      title: 'Short Description',
      name: 'description',
      type: 'text',
      rows: 2
    },
    {
      title: 'Poster',
      name: 'poster',
      type: 'image',
      description:
        'Representative image used in page sharing and search engine results'
    },
    faviconField,
    {
      title: 'Badge Text',
      name: 'badge',
      type: 'string',
      description:
        'Optional circular popup text that displays on CLPs (for ex. "new item")'
    },
    {
      title: 'Hero',
      name: 'hero',
      type: 'object',
      fields: [
        {
          ...mediaField,
          title: 'Default Media',
          name: 'defaultMedia'
        },
        {
          ...mediaField,
          title: 'Hover Media',
          name: 'hoverMedia'
        },
        {
          title: 'Smooth Transition',
          name: 'smooth',
          type: 'boolean',
          layout: 'checkbox'
        }
      ],
      fieldset: 'hero'
    },
    {
      ...galleryField,
      title: 'Hero Gallery',
      name: 'heroGallery',
      description:
        'Gallery is optional and takes the place of the hero media above on product pages if used',
      fieldset: 'hero'
    },
    {
      ...thumbField,
      validation: Rule => Rule.required()
    },
    colorsField,
    {
      title: 'Transition Background Color',
      name: 'colorSectionBackground',
      type: 'color',
      description:
        'If defined, the background color that will be used for sections without a defined background color'
    },
    {
      title: 'Body Title',
      name: 'textTitle',
      type: 'string'
    },
    {
      title: 'Body Subtitle',
      name: 'textSubtitle',
      type: 'text',
      rows: 2
    },
    {
      ...contentEditor,
      title: 'Body Content',
      description: '(ie. "What\'s Inside?" for Sparkling Water)'
    },
    {
      ...profileField,
      fieldset: 'attributes'
    },
    {
      ...featuresField,
      title: 'Bulleted List',
      fieldset: 'attributes'
    },
    {
      title: 'Highlighted Ingredients',
      name: 'ingredients',
      type: 'array',
      of: [
        {
          type: 'reference',
          to: [{ type: 'ingredient' }]
        }
      ],
      validation: Rule =>
        Rule.max(4).error(
          "A product can't have more than 4 highlighted ingredients"
        ),
      fieldset: 'water'
    },
    {
      ...basicEditor,
      title: 'Ingredient Info',
      name: 'ingredientInfo',
      fieldset: 'water'
    },
    {
      ...basicEditor,
      title: 'Nutrition Facts',
      name: 'nutritionFacts',
      fieldset: 'water'
    },
    {
      title: 'Lookbook Gallery',
      name: 'gallery',
      type: 'object',
      fields: [
        galleryField,
        {
          ...linkField,
          title: 'Optional CTA'
        }
      ],
      fieldset: 'realitywear'
    },
    {
      title: 'Social Media',
      name: 'relatedSocialMedia',
      type: 'object',
      fields: [
        {
          title: 'Optional Title',
          name: 'title',
          type: 'string'
        },
        socialMedia
      ],
      fieldset: 'related'
    },
    {
      ...linkField,
      title: 'Related Products Title',
      name: 'relatedProductsTitleLink',
      fieldset: 'related'
    },
    {
      title: 'Related Products',
      name: 'relatedProducts',
      type: 'object',
      fields: [
        {
          title: 'Featured Product',
          name: 'featured',
          type: 'reference',
          to: [{ type: 'product' }]
        },
        {
          title: 'Products',
          name: 'products',
          type: 'array',
          of: [
            {
              type: 'reference',
              to: [{ type: 'product' }]
            }
          ]
        }
      ],
      fieldset: 'related'
    },
    {
      name: 'descriptionAlt',
      title: 'Alt Description',
      type: 'text',
      rows: 2,
      options: {
        collapsible: true
      },
      fieldset: 'alt'
    },
    {
      ...thumbField,
      options: {
        collapsible: false
      },
      title: 'Alt Thumb',
      name: 'thumbAlt',
      fieldset: 'alt'
    },
    {
      title: 'Shopify',
      name: 'shopify',
      type: 'object',
      options: {
        collapsible: true,
        collapsed: true
      },
      fields: [
        {
          title: 'Product',
          name: 'product',
          type: 'object',
          fields: [
            {
              title: 'Shopify ID',
              name: 'id',
              type: 'string'
            },
            {
              title: 'Product Title',
              name: 'title',
              type: 'string'
            },
            {
              title: 'Product Image',
              name: 'image',
              type: 'string',
              hidden: true
            }
          ],
          components: {
            input: ShopifyProductInput
          },
          validation: Rule =>
            Rule.required().error('A Shopify product is required.')
        },
        {
          title: 'Subscription',
          name: 'subscription',
          type: 'object',
          fields: [
            {
              title: 'Shopify ID',
              name: 'id',
              type: 'string'
            },
            {
              title: 'Product Title',
              name: 'title',
              type: 'string'
            }
          ],
          components: {
            input: ShopifyProductInput
          },
          description:
            'Must be a duplicate subscription product added to ReCharge'
        },
        {
          title: 'Default SKU',
          name: 'defaultSku',
          type: 'object',
          fields: [
            {
              title: 'SKU',
              name: 'sku',
              type: 'string',
              description:
                'Optional SKU of the product variant that will be selected by default (case-sensitive)'
            },
            {
              title: 'Subscription',
              name: 'subscription',
              type: 'boolean',
              layout: 'checkbox',
              description: 'Default to subscription'
            }
          ]
        },
        {
          title: 'Subscription-Only SKUs',
          name: 'subscriptionSkus',
          type: 'array',
          of: [{ type: 'string' }],
          description: 'SKUs that do not allow single-purchase'
        },
        {
          title: 'Default Product Type',
          name: 'defaultProductType',
          type: 'string',
          options: {
            list: [
              { title: 'Single-Purchase', value: 'single' },
              { title: 'Subscription', value: 'subscription' }
            ],
            layout: 'radio'
          }
        },
        {
          title: 'Subscription Notice',
          name: 'subscriptionNotice',
          type: 'string',
          description:
            'Defaults to "You\'re in control. Try, adjust, or cancel at anytime"'
        },
        {
          title: 'Subscription Tooltip',
          name: 'subscriptionTooltip',
          type: 'string',
          description: 'Short, additional info ie. "Only available for 12 pack"'
        },
        {
          title: 'Subscription Save Label',
          name: 'subscriptionSaveLabel',
          type: 'string',
          description: 'label for radio button e.g. Subscribe and save $10'
        },
        {
          title: 'Single Purchase Notice',
          name: 'singlePurchaseNotice',
          type: 'string',
          description: 'Hidden if no value.'
        },
        {
          title: 'Single Purchase Tooltip',
          name: 'singleTooltip',
          type: 'string',
          description: 'Short, additional info ie. "Not available for 12 pack"'
        },
        {
          title: 'Shipping Notice',
          name: 'shippingNotice',
          type: 'string',
          description: 'i.e. "Free shipping on all orders!"'
        }
      ]
    },
    {
      title: 'Add to Cart CTA Text',
      name: 'ctaText',
      type: 'string',
      description: 'Defaults to "Add to Cart"'
    },
    {
      title: 'Amazon Link',
      name: 'amazonLink',
      type: 'url',
      validation: Rule => Rule.uri({ scheme: 'https', allowRelative: false })
    },
    {
      title: 'Amazon CTA Text',
      name: 'amazonCta',
      type: 'string'
    }
  ],
  preview: {
    select: {
      title: 'title',
      image: 'thumb.image',
      shopifyImage: 'shopify.image'
    },
    prepare: ({ title = 'New Product', image, shopifyImage }) => ({
      media: image || shopifyImage,
      title
    })
  }
}
