import groq from 'groq'
import thumbField from './thumb'
import altThumbField from './thumb-alt'
import galleryThumbField from './gallery-thumb'

export default alt => {
  if (alt) {
    return groq`{
      cbd,
      "category": category->slug.current,
      badge,
      waitlistKlaviyoListId,
      hideFromNavigation,
      hideFromCollection,
      waitlistHeader,
      ${altThumbField},
      ${galleryThumbField},
      shopify,
      ingredients[]-> {
        title
      }
    }`
  } else {
    return groq`{
      cbd,
      "category": category->slug.current,
      badge,
      waitlistKlaviyoListId,
      hideFromNavigation,
      hideFromCollection,
      waitlistHeader,
      ${thumbField},
      ${galleryThumbField},
      shopify,
      ingredients[]-> {
        title
      }
    }`
  }
}
