import groq from 'groq'
import mediaField from './media'

export default groq`{
  smooth,
  defaultMedia{
    ...${mediaField}
  },
  defined(hoverMedia) => {
    "hoverMedia": hoverMedia{
      ...${mediaField}
    }
  }
}`
