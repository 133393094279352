/* eslint-disable no-undef */

export default () => {
  ;(function() {
    Grin = window.Grin || (window.Grin = [])
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.src = 'https://d38xvr37kwwhcm.cloudfront.net/js/grin-sdk.js'
    const x = document.getElementsByTagName('script')[0]
    x.parentNode.insertBefore(s, x)
  })()
}
