import groq from 'groq'
import pageFields from '../fields/page'
import productFields from '../fields/product'
import heroField from '../fields/hero'

export default alt => {
  if (alt) {
    return groq`
      *[_type == "featuredProduct"][0]{
        product->{
          ...${pageFields},
          ...${productFields(alt)},
          descriptionAlt,
          hero{
            ...${heroField}
          }
        }
      }
  `
  } else {
    return groq`
    *[_type == "featuredProduct"][0]{
      product->{
        ...${pageFields},
        ...${productFields()},
        description,
        hero{
          ...${heroField}
        }
      }
    }
  `
  }
}
