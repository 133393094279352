<template>
  <div class="cart-clouds">
    <div class="cart-clouds__clouds">
      <div v-for="i in 2" :key="`cloud-${i}`" class="cart-clouds__cloud">
        <float :reverse="i === 1" :offset="i === 1 ? '15%' : '-10%'" always>
          <cloud
            :distance="i + 1"
            :image="i * 4"
            :dismissed="!clouds.includes(i)"
            dismissable
            visible
            @click.native="cloudDismiss(i)"
          />
        </float>
      </div>
      <fade enter-delay="1" leave-delay="1" mode="out-in" appear>
        <div v-if="hasClouds" class="inline-block cart-clouds__message">
          <h4 class="whitespace-no-wrap">Nothing here but clouds</h4>
        </div>
      </fade>
    </div>
    <fade enter-delay="1.5" mode="out-in" appear>
      <div
        v-if="!hasClouds && featuredProduct"
        class="w-full cart-clouds__message"
      >
        <h4 class="mb-em">
          Can&rsquo;t decide?<br />
          You could try
        </h4>
        <product-thumb
          :product="featuredProduct"
          :title="featuredProduct.title"
          set-colors-manually
          class="cart-clouds__thumb"
          @mouseover.native="setColor"
          @mouseout.native="unsetColor"
          @click.native="trackProduct('Suggested Product in Cart Clicked')"
        />
      </div>
      <div v-else-if="!hasClouds" class="inline-block cart-clouds__message">
        <h4 class="whitespace-no-wrap">Nothing here</h4>
      </div>
    </fade>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProductThumb from '~/components/shop/products/product-thumb.vue'

export default {
  name: 'CartClouds',

  components: {
    ProductThumb
  },

  data: () => ({
    clouds: [1, 2]
  }),

  computed: {
    ...mapState('shop', {
      featuredProduct: state => state.featuredProduct
    }),
    ...mapState({
      googleId: state => state.googleId
    }),
    hasClouds() {
      return this.clouds.length > 0
    },
    productColor() {
      const product = this.featuredProduct
      let color
      if (product && product.colors) {
        color = this.getBackgroundColor(product.colors.backgroundColors)
      }
      return color
    }
  },

  watch: {
    hasClouds(has) {
      if (has) {
        this.$nextTick(this.unsetColor)
      } else {
        this.trackProduct('Suggested Product in Cart Viewed')
      }
    }
  },

  methods: {
    cloudDismiss(index) {
      const cloudsIndex = this.clouds.indexOf(index)
      if (cloudsIndex > -1) {
        this.clouds.splice(cloudsIndex, 1)
      }
    },
    setColor() {
      const color = this.productColor
      if (color) {
        this.$emit('update:color', color)
      } else {
        this.unsetColor()
      }
    },
    unsetColor() {
      this.$emit('update:color')
    },
    trackProduct(e) {
      const product = this.featuredProduct
      if (!e || !product) {
        return
      }
      this.$segment.track(e, {
        google_client_id: this.googleId,
        product_id: product.id,
        name: product.title,
        url: `${process.env.canonicalUrl}${this.$route.path}`
      })
    }
  }
}
</script>

<style>
.cart-clouds {
  display: block;
  text-align: center;
  width: 100%;
}

.cart-clouds,
.cart-clouds__clouds,
.cart-clouds__message {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.cart-clouds__clouds {
  display: inline-block;
  width: 100%;
}

.cart-clouds__message {
  text-transform: lowercase;
}

.cart-clouds__cloud {
  position: absolute;
}

.cart-clouds__cloud:nth-child(1) {
  bottom: 0;
  left: 0;
  transform: translate(50%, -50%);
}

.cart-clouds__cloud:nth-child(2) {
  right: 0;
  top: 0;
  transform: translate(-50%, 50%);
}

.cart-clouds__thumb.thumb {
  --thumb-hover-size: 10%;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>
