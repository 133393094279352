import linkField from './link'

export default {
  title: 'Features',
  name: 'features',
  type: 'object',
  fields: [
    {
      title: 'Optional Title',
      name: 'title',
      type: 'string'
    },
    {
      title: 'List',
      name: 'list',
      type: 'array',
      of: [
        {
          title: 'Item',
          name: 'item',
          type: 'string'
        }
      ]
    },
    {
      ...linkField,
      title: 'Optional CTA'
    }
  ]
}
