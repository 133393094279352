import basicEditor from '../blocks/basic-editor'
import mediaField from './media'

export default {
  title: 'Profile',
  name: 'profile',
  type: 'object',
  fields: [
    {
      title: 'Optional Title',
      name: 'title',
      type: 'string'
    },
    mediaField,
    basicEditor
  ]
}
