import groq from 'groq'
import pageFields from '../fields/page'
import productFields from '../fields/product'
import linkField from '../fields/link'

export const categoryFields = groq`{
  defined(products) => {
    "products": products[]->{
      ...${pageFields},
      ...${productFields()}
    }
  },
  defined(menu) => {
    "menu": menu[]{
      ...${linkField}
    }
  }
}`

// NOTE: because we can't expand a nested object
// this returns a nested productMenu object
export default groq`
  *[_type == "menus"][0]{
    productMenu[]->{
      ...${pageFields},
      ...${productFields()},
      ...${categoryFields},
      cbd,
      "subcategories": *[_type == "productCategory" && references(^._id)]{
        ...${pageFields},
        ...${categoryFields}
      }
    }
  }
`
