/* snippet pasted live & direct from justuno */

export default ({ env }) => {
  const id = env.justunoId
  if (!id) {
    return
  }
  window.ju_num = id
  window.asset_host = 'https://cdn.jst.ai/'
  ;(function(i, s, o, g, r, a, m) {
    i[r] =
      i[r] ||
      function() {
        ;(i[r].q = i[r].q || []).push(arguments)
      }
    a = s.createElement(o)
    m = s.getElementsByTagName(o)[0]
    a.async = 1
    a.src = g
    m.parentNode.insertBefore(a, m)
  })(window, document, 'script', window.asset_host + 'vck.js', 'juapp')
}
