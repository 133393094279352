import siteSettingsQuery from '~/sanity/queries/site-settings'
import siteMenusQuery from '~/sanity/queries/site-menus'

export const state = () => ({
  settings: null,
  loginLink: null,
  primaryMenu: {},
  secondaryMenu: {},
  legalPageMenu: [],
  googleClientId: undefined,
  googleSessionId: undefined,
  segmentAid: undefined,
  discountCode: undefined,
  featureFlag: undefined,
  refersionCode: undefined
})

export const actions = {
  async init({ dispatch, commit }) {
    const settings = await dispatch('fetchSiteSettings')
    const menus = await dispatch('fetchSiteMenus')
    commit('setSiteSettings', settings)
    commit('setSiteMenus', menus)
  },
  fetchSiteSettings() {
    return this.$sanity.fetch(siteSettingsQuery)
  },
  fetchSiteMenus() {
    return this.$sanity.fetch(siteMenusQuery)
  },
  async nuxtServerInit({ dispatch }, { env, $sentry }) {
    if (env.previewMode && process.server) {
      return
    }
    try {
      await dispatch('init')
      if (env.shopify) {
        await dispatch('shop/init')
      }
    } catch (e) {
      $sentry.captureException(e)
      // Fail on build
      throw e
    }
  },
  nuxtClientInit({ dispatch }, context) {
    dispatch('shop/nuxtClientInit')
  },
  getImageUrl(ctx, { image, width = 1024, height = 1024, crop = false }) {
    if (!image) {
      return
    }
    let sanityImage
    try {
      sanityImage = this.$sanityImage(image)
      if (crop) {
        sanityImage = sanityImage
          .ignoreImageParams()
          .size(width, height)
          .fit('min')
      } else {
        sanityImage = sanityImage
          .ignoreImageParams()
          .size(width, height)
          .fit('max')
      }
      if (!image.noCompression) {
        sanityImage = sanityImage.auto('format')
      }
    } catch (e) {
      this.$sentry.captureException(e)
    }
    return sanityImage ? sanityImage.url() : undefined
  }
}

export const mutations = {
  setSiteSettings(state, settings) {
    state.settings = settings
  },
  setSiteMenus(state, menus = {}) {
    menus = Object.entries(menus)
    for (const [key, val] of menus) {
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        state[key] = val
      }
    }
  },
  setGoogleClientId(state, id) {
    state.googleClientId = id || null
  },
  setGoogleSessionId(state, id) {
    state.googleSessionId = id || null
  },
  setSegmentAid(state, aid) {
    state.segmentAid = aid || null
  },
  setDiscountCode(state, code) {
    state.discountCode = code || null
  },
  setFeatureFlag(state, flag) {
    state.featureFlag = flag || null
  },
  setRefersionCode(state, code) {
    state.refersionCode = code || null
  }
}
